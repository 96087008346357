const ScreenShotData = [
  {
    porgress: "70",

    time: "07:55",

    path: "https://images.unsplash.com/photo-1658808183982-f07bbbf67bd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
  },
  {
    porgress: "85",
    time: "06:55",

    path: "https://images.unsplash.com/photo-1659035259836-0fe8c358a064?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
  },
  {
    porgress: "25",
    time: "02:55",

    path: "https://images.unsplash.com/photo-1659035259836-0fe8c358a064?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
  },
  {
    porgress: "59",
    time: "08:55",

    path: "https://images.unsplash.com/photo-1659035259836-0fe8c358a064?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
  },
  {
    porgress: " 95",
    time: "01:55",

    path: "https://images.unsplash.com/photo-1658808183982-f07bbbf67bd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
  },
  {
    porgress: "65",
    time: "02:55",

    path: "https://images.unsplash.com/photo-1658808183982-f07bbbf67bd7?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1632&q=80",
  },
];
export { ScreenShotData };
